import React from 'react';
import {NavLink, useNavigate} from "react-router-dom"

const LearnMore = () => {
    const navi = useNavigate();
  return (
    <div id="about" className="section wb">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="message-box">
                <h4>About Us</h4>
                <h2>Qamar Soft Solutions</h2>
                <p className="lead">
                Since its inception, Qamar Soft Solutions has been creating customized web and software solutions to grow your business, reach new customers, simplify management. 
                </p>
                <p>
                Born from the collaboration of a group of professionals, who have been working for years in the web, software and systems development sectors, we offer a complete range of services, always intending to satisfy the individual customer’s needs in the best possible way.
                </p>
                <a className="btn11" href="#0">
                  <span onClick={() => navi("/about")}>Learn More</span>
                  <div className="transition" />
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="post-media wow fadeIn">
                <img
                  src="/static/uploads/about_01.jpg"
                  alt=""
                  className="img-fluid img-rounded"
                />
                <a
                  href=""
                  data-rel="prettyPhoto[gal]"
                >
                  {/* <i className="flaticon-play-button" /> */}
                </a>
              </div>
            </div>
          </div>
          <hr className="hr1" />
          <div className="row">
            <div className="col-md-6">
              <div className="message-box">
                <h2>Become a Partner</h2>
                <p className="lead">
                We work on the quality of business processes and human resources, on business innovation and on sustainability in order to offer customers distinctive know-how and practical approaches to optimize processes and internal performance within the company.
                </p>
                <ul>
                  <li>
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    />{" "}
                    Fast and cheap- We are able to respond quickly to your requests while maintaining a competitive price.
                  </li>
                  <li>
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    />{" "}
                    Services- The wide choice of services allows us to have the right offer for you.
                  </li>
                  <li>
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    />{" "}
                    Custom development– We do not use automatic website creation services; we do everything carefully by hand.
                  </li>
                  <li>
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    />{" "}
                    Flexibility- We know how to adapt to your needs.
                  </li>
                </ul>
                <a href="#" className="btn11">
                  <span onClick={() => navi("/about")}>Learn More</span>
                  <div className="transition" />
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="message-box">
                <h2>Who We Are</h2>
                <p className="lead">
                Since its inception, Qamar Soft Solutions has been creating customized web and software solutions to grow your business, reach new customers, simplify management. Born from the collaboration of a group of professionals, who have been working for years in the web,
                </p>
                <p>
                software and systems development sectors, we offer a complete range of services, always intending to satisfy the individual customer’s needs in the best possible way. The flexibility of our structure allows us to aim at the quality and specificity of the service offered, creating tailor-made solutions for everyone’s needs.
                </p>
                <a to='/about' className="btn11" onClick={() => navi("/about")}>
                  <span onClick={() => navi("/about")} >Learn More</span>
                  <div className="transition" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default LearnMore