import './App.css';
import Mainpage from './components/Mainpage';
import About from './pages/About';
import Services from './pages/Services';
import {BrowserRouter, Routes, Route} from "react-router-dom"
import Contact from './pages/Contact';
import Features from './pages/Features';
import Portfolio from './pages/Portfolio';
import Testimonial from './pages/Testimonial';

function App() {
  return (
    <BrowserRouter>  
      <Routes>
        <Route path="/" element={<> <Mainpage /> </>} />
        <Route path="/about" element={<> <About /> </>} />
        <Route path="/services" element={<> <Services /> </>} />
        <Route path="/portfolio" element={<> <Portfolio /> </>} />
        <Route path="/features" element={<> <Features /> </>} />
        <Route path="/testimonials" element={<> <Testimonial /> </>} />
        <Route path="/contact" element={<> <Contact /> </>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
