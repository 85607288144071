import React, { useState } from "react";

const Slider = () => {

  return (
    <div className="slider-area">
    <div className="slider-wrapper owl-carousel">
      <div className="slider-item text-center home-one-slider-otem slider-item-four slider-bg-one">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="slider-content-area">
                <div className="slide-text">
                  <h1 className="homepage-three-title">
                    Welcome to <span>Linkweb</span> Web Agency
                  </h1>
                  <h2>
                    With Linkweb responsive landing page template, <br />
                    you can promote your all web design &amp; development
                    services.{" "}
                  </h2>
                  <div className="slider-content-btn">
                    <a className="btn11" href="#">
                      Read More
                      <div className="transition" />
                    </a>
                    <a className="btn11" href="#">
                      Contact
                      <div className="transition" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="slider-item text-center home-one-slider-otem slider-item-four slider-bg-two">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="slider-content-area">
                <div className="slide-text">
                  <h1 className="homepage-three-title">
                    Welcome to <span>Linkweb</span> Web Agency
                  </h1>
                  <h2>
                    With Linkweb responsive landing page template, <br />
                    you can promote your all web design &amp; development
                    services.{" "}
                  </h2>
                  <div className="slider-content-btn">
                    <a className="btn11" href="#">
                      <span>Read More</span>
                      <div className="transition" />
                    </a>
                    <a className="btn11" href="#">
                      <span>Contact</span>
                      <div className="transition" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="slider-item text-center home-one-slider-otem slider-item-four slider-bg-three">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="slider-content-area">
                <div className="slide-text">
                  <h1 className="homepage-three-title">
                    Welcome to <span>Linkweb</span> Web Agency
                  </h1>
                  <h2>
                    With Linkweb responsive landing page template, <br />
                    you can promote your all web design &amp; development
                    services.
                  </h2>
                  <div className="slider-content-btn">
                    <a className="btn11" href="#">
                      <span>Read More</span>
                      <div className="transition" />
                    </a>
                    <a className="btn11" href="#">
                      <span>Contact</span>
                      <div className="transition" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Slider;
