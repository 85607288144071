import React from 'react'

const Count = () => {
  return (
    <div
        className="parallax section parallax-off"
        data-stellar-background-ratio="0.9"
        style={{ backgroundImage: 'url("/static/uploads/parallax_04.jpg")' }}
      >
        <div className="container">
          <div className="row text-center stat-wrap">
            <div className="col-md-3 col-sm-6 col-xs-12">
              <span className="global-radius icon_wrap">
                <i className="flaticon-briefcase" />
              </span>
              <p className="stat_count">1,769</p>
              <h3 className="line-hv" style={{color:"#fff"}}>Successful Projects</h3>
            </div>
            {/* end col */}
            <div className="col-md-3 col-sm-6 col-xs-12">
              <span className="global-radius icon_wrap">
                <i className="icon icon-location" />
              </span>
              <p className="stat_count">5</p>
              <h3 className="line-hv" style={{color:"#fff"}}>Happy Clients</h3>
            </div>
            {/* end col */}
            <div className="col-md-3 col-sm-6 col-xs-12">
              <span className="global-radius icon_wrap">
                <i className="flaticon-idea" />
              </span>
              <p className="stat_count">100%</p>
              <h3 className="line-hv"style={{color:"#fff"}}>Customer Services</h3>
            </div>
            {/* end col */}
            <div className="col-md-3 col-sm-6 col-xs-12">
              <span className="global-radius icon_wrap">
                <i className="flaticon-customer-service" />
              </span>
              <p className="stat_count">4300</p>
              <h3 className="line-hv" style={{color:"#fff"}}>Answered Questions</h3>
            </div>
            {/* end col */}
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </div>
  )
}

export default Count