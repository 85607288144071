import React from 'react'
import Navbar from '../components/Navbar'
import TopNavbar from '../components/TopNavbar'
import Footer from '../components/Footer'
import FollowUp from '../components/FollowUp'

const Services = () => {
  return (
    <>
    <TopNavbar />
    <Navbar />
  <div className="banner-area banner-bg-1">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="banner">
            <h2>
              <span>Our Services</span>
            </h2>
            <ul className="page-title-link">
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <a href="#">Our Services</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="services" className="parallax section lb">
    <div className="container">
      <div className="section-title text-center">
        <h3>Our Service</h3>
        <p className="lead">
          Our Service unlimited solutions to all your business needs. in the
          installation package we prepare search engine optimization, social
          media support, we provide corporate identity and graphic design
          services.
        </p>
      </div>
      <hr className="hr1" />
      <div className="row text-center">
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="service-widget">
                <div className="post-media wow fadeIn">
                  <a
                    href="/"
                    data-rel="prettyPhoto[gal]"
                    className="hoverbutton global-radius"
                  >
                    <i className="flaticon-unlink" />
                  </a>
                  <img
                    src="/static/uploads/features_01.jpg"
                    alt=""
                    className="img-fluid img-rounded"
                  />
                </div>
                <h1 style={{ marginTop: "1rem" }}>Web Development</h1>
                <p style={{ margin: "10px 10px", paddingBottom: "10px" }}>
                  Qamar Soft Solutions has always been in step with the constant
                  and rapid evolution of the World Wide Web.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="service-widget">
                <div className="post-media wow fadeIn">
                  <a
                    href="uploads/features_02.jpg"
                    data-rel="prettyPhoto[gal]"
                    className="hoverbutton global-radius"
                  >
                    <i className="flaticon-unlink" />
                  </a>
                  <img
                    src="/static/uploads/features_02.jpg"
                    alt=""
                    className="img-fluid img-rounded"
                  />
                </div>
                <h1 style={{ marginTop: "1rem" }}>Mobile App Development</h1>
                <p style={{ margin: "10px 10px", paddingBottom: "10px" }}>
                  Our experts work alongside them, who develop solid
                  applications for iOS and Android
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="service-widget">
                <div className="post-media wow fadeIn">
                  <a
                    href="/"
                    data-rel="prettyPhoto[gal]"
                    className="hoverbutton global-radius"
                  >
                    <i className="flaticon-unlink" />
                  </a>
                  <img
                    src="/static/uploads/features_03.jpg"
                    alt=""
                    className="img-fluid img-rounded"
                  />
                </div>
                <h1 style={{ marginTop: "1rem" }}>AR/VR Development </h1>
                <p style={{ margin: "10px 10px", paddingBottom: "10px" }}>
                  If you are looking for a software consulting company that can
                  support you at 360 °
                </p>
              </div>
            </div>
          </div>
    </div>
  </div>
  <FollowUp/>
  <Footer/>
  <a href="#" id="scroll-to-top" className="dmtop global-radius">
    <i className="fa fa-angle-up" />
  </a>
</>

  )
}

export default Services
