import React from 'react'

const FollowUp = () => {
  return (
    <div>
         <div className="section wb">
    <div className="container">
      <div className="row text-center">
        <div className="col-md-3 col-sm-6">
          <div className="about-item">
            <div className="about-icon">
              <span className="icon icon-display" />
            </div>
            <div className="about-text">
              <h3>
                <a href="#">Website Development</a>
              </h3>
              <p>
              Qamar Soft Solutions has always been in step with the constant and rapid evolution of the World Wide Web.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="about-item">
            <div className="about-icon">
              <span className="icon icon-database" />
            </div>
            <div className="about-text">
              <h3>
                <a href="#">Database Development</a>
              </h3>
              <p>
              Database development is the server-side web programming and refers to all that the user can not see in their browsers
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="about-item">
            <div className="about-icon">
              <span className="icon icon-mobile" />
            </div>
            <div className="about-text">
              <h3>
                {" "}
                <a href="#">Mobile App Development</a>
              </h3>
              <p>
               Mobile communication technologies to reach a huge audience of potential new customers
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="about-item">
            <div className="about-icon">
              <span className="icon icon-cloud" />
            </div>
            <div className="about-text">
              <h3>
                {" "}
                <a href="#">Salesforce Development</a>
              </h3>
              <p>
              Qamar Soft Solutions implements the CRM software solution best suited to the specific needs of the company
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="about-item">
            <div className="about-icon">
              <span className="icon icon-database" />
            </div>
            <div className="about-text">
              <h3>
                {" "}
                <a href="#">Backend Development</a>
              </h3>
              <p>
              Our back-end developers focus on efficiency, security, structure (architecture) and data management
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="about-item">
            <div className="about-icon">
              <span className="icon icon-laptop" />
            </div>
            <div className="about-text">
              <h3>
                {" "}
                <a href="#">Software Consulting</a>
              </h3>
              <p>
              If you are looking for a software consulting company that can support you at 360 ° to modernize your company’s infrastructure
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="about-item">
            <div className="about-icon">
              <span className="icon icon-laptop" />
            </div>
            <div className="about-text">
              <h3>
                {" "}
                <a href="#">IoT Solutions</a>
              </h3>
              <p>
           Harnessing the power of interconnected devices for transformative automation,  seamless connectivity across industries.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="about-item">
            <div className="about-icon">
              <span className="icon icon-user" />
            </div>
            <div className="about-text">
              <h3>
                {" "}
                <a href="#">DevOps</a>
              </h3>
              <p>
             Continuous integration, practices fostering efficiency, accelerated software development and deployment lifecycles.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>
  )
}

export default FollowUp