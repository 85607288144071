import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import TopNavbar from "./TopNavbar";
import Slider from "./Slider";
import LearnMore from "./LearnMore";
import Header from "./Header";
import Test from "./Test";
import Count from "./Count";
import FollowUp from "./FollowUp";

const Mainpage = () => {
  return (
    <>
      <TopNavbar />
      <Navbar />
      <Header />
      <Slider />
      <LearnMore />
      <Count />
      <div id="services" className="parallax section lb">
        <div className="container">
          <div className="section-title text-center">
            <h3>Our Services</h3>
            <p className="lead">
              We leverage advanced technology to deliver solutions that exceed
              expectations.
            </p>
          </div>
          <div className="row text-center">
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="service-widget">
                <div className="post-media wow fadeIn">
                  <a
                    href="/"
                    data-rel="prettyPhoto[gal]"
                    className="hoverbutton global-radius"
                  >
                    <i className="flaticon-unlink" />
                  </a>
                  <img
                    src="/static/uploads/features_01.jpg"
                    alt=""
                    className="img-fluid img-rounded"
                  />
                </div>
                <h1 style={{ marginTop: "1rem" }}>Web Development</h1>
                <p style={{ margin: "10px 10px", paddingBottom: "10px" }}>
                  Qamar Soft Solutions has always been in step with the constant
                  and rapid evolution of the World Wide Web.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="service-widget">
                <div className="post-media wow fadeIn">
                  <a
                    href="uploads/features_02.jpg"
                    data-rel="prettyPhoto[gal]"
                    className="hoverbutton global-radius"
                  >
                    <i className="flaticon-unlink" />
                  </a>
                  <img
                    src="/static/uploads/features_02.jpg"
                    alt=""
                    className="img-fluid img-rounded"
                  />
                </div>
                <h1 style={{ marginTop: "1rem" }}>Mobile App Development</h1>
                <p style={{ margin: "10px 10px", paddingBottom: "10px" }}>
                  Our experts work alongside them, who develop solid
                  applications for iOS and Android
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="service-widget">
                <div className="post-media wow fadeIn">
                  <a
                    href="/"
                    data-rel="prettyPhoto[gal]"
                    className="hoverbutton global-radius"
                  >
                    <i className="flaticon-unlink" />
                  </a>
                  <img
                    src="/static/uploads/features_03.jpg"
                    alt=""
                    className="img-fluid img-rounded"
                  />
                </div>
                <h1 style={{ marginTop: "1rem" }}>AR/VR Development </h1>
                <p style={{ margin: "10px 10px", paddingBottom: "10px" }}>
                  If you are looking for a software consulting company that can
                  support you at 360 °
                </p>
              </div>
            </div>
          </div>
          <div className="row text-center" style={{ marginTop: "2rem" }}></div>
        </div>
      </div>
      <FollowUp />
      <div
        className="parallax section noover"
        data-stellar-background-ratio="0.7"
        style={{ backgroundImage: 'url("/static/uploads/parallax_05.png")' }}
      >
        <div className="container">
          <div className="row text-center align-items-center">
            <div className="col-md-6">
              <div className="customwidget text-left">
                <h1>Why Choose us?</h1>
                <p>
                  We are skilled technologists that apply our passion for
                  technology to create successful products and solutions for our
                  clients. Our passion drives us to be innovative, creative, and
                  experts.
                </p>
                <ul className="list-inline">
                  <li>
                    <i className="fa fa-check" /> Latest Technologies
                  </li>
                  <li>
                    <i className="fa fa-check" /> SEO Optimized Designs
                  </li>
                  <li>
                    <i className="fa fa-check" /> Affordable Pricing
                  </li>
                  <li>
                    <i className="fa fa-check" /> Knowledgeable Team
                  </li>
                  <li>
                    <i className="fa fa-check" /> User Friendly Interface
                  </li>
                  <li>
                    <i className="fa fa-check" /> Coding Ethics
                  </li>
                </ul>
                <a href="#" className="btn11">
                  <span>Learn More</span>
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="text-center image-center hidden-sm hidden-xs">
                <img
                  src="/static/uploads/img-123.png"
                  alt=""
                  className="img-fluid wow fadeInUp"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="features" className="section lb">
        <div className="container">
          <div className="section-title text-center">
            <h3>Our Technology Stack</h3>
            <p className="lead">
              Selecting the right tech stack is a real challenge but our team of
              professional web developers will help you choose the right tools
              for delivering a top-notch web application with all the
              functionality you need.
            </p>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <ul className="features-left">
                <li
                  className="wow fadeInLeft"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <i className="flaticon-wordpress-logo" />
                  <div className="fl-inner">
                    <h4>WordPress Installation</h4>
                    <p>
                      WordPress installation: download, configure, setup
                      database, install theme, customize, publish.
                    </p>
                  </div>
                </li>
                <li
                  className="wow fadeInLeft"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <i className="flaticon-windows" />
                  <div className="fl-inner">
                    <h4>Browser Compatible</h4>
                    <p>
                      Browser compatible: website displays properly across all
                      major browsers, seamless user experience.
                    </p>
                  </div>
                </li>
                <li
                  className="wow fadeInLeft"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >
                  <i className="flaticon-price-tag" />
                  <div className="fl-inner">
                    <h4>eCommerce Ready</h4>
                    <p>
                      E-commerce ready: product catalog, secure payments,
                      inventory management, seamless checkout.
                    </p>
                  </div>
                </li>
                <li
                  className="wow fadeInLeft"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <i className="flaticon-new-file" />
                  <div className="fl-inner">
                    <h4>Easy to Customize</h4>
                    <p>
                    Easy to customize: user-friendly interface enables effortless personalization of website design.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-4 hidden-xs hidden-sm">
              <img
                src="/static/uploads/ipad.png"
                className="img-center img-fluid"
                alt=""
              />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <ul className="features-right">
                <li
                  className="wow fadeInRight"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <i className="flaticon-pantone" />
                  <div className="fr-inner">
                    <h4>Limitless Colors</h4>
                    <p>
                    Limitless colors: endless possibilities for vibrant and customizable website designs.
                    </p>
                  </div>
                </li>
                <li
                  className="wow fadeInRight"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <i className="flaticon-cloud-computing" />
                  <div className="fr-inner">
                    <h4>Lifetime Update</h4>
                    <p>
                    Lifetime update: continuous support and enhancements for long-term website performance
                    </p>
                  </div>
                </li>
                <li
                  className="wow fadeInRight"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >
                  <i className="flaticon-line-graph" />
                  <div className="fr-inner">
                    <h4>SEO Friendly</h4>
                    <p>
                    SEO friendly: boost visibility, increase organic traffic, optimize website for search engines.
                    </p>
                  </div>
                </li>
                <li
                  className="wow fadeInRight"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <i className="flaticon-coding" />
                  <div className="fr-inner">
                    <h4>Simple Clean Code</h4>
                    <p>
                    Simple clean code: efficient, readable, and maintainable for smooth website development.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
   
      <a href="#" id="scroll-to-top" className="dmtop global-radius">
        <i className="fa fa-angle-up" />
      </a>
    </>
  );
};

export default Mainpage;
