import React from "react";
import Navbar from "../components/Navbar";
import TopNavbar from "../components/TopNavbar";
import Footer from "../components/Footer";
import Count from "../components/Count";

const Portfolio = () => {
  return (
    <>
      <TopNavbar />
      <Navbar />
      <div className="banner-area banner-bg-1">
        <div className="container">
          
          <div className="row">
            <div className="col-md-12">
              <div className="banner">
                <h2>
                  <span>Our Portfolio</span>
                </h2>
                <ul className="page-title-link">
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li>
                    <a href="#">Our Portfolio</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="about" className="section wb">
        <div className="container">
        <div className="section-title text-center">
        <h3>State of the art technology | Focus on innovation | Fast delivery time</h3>
        <p className="lead">
        Qamar Soft Solutions has in-depth experience in mobile app development, web design, development services, and impeccable e-commerce development services from Source Soft Solutions. Our portfolio includes a wide range of projects that we have completed and turns our clients’ passions into brands.
        </p>
      </div>
          <div className="row">
            <div className="col-md-6">
              <div className="message-box">
                <h2>Wide range of services</h2>
                <p className="lead">
                We provide innovative digital promotion, animation services, mobile app development, backend development, software consulting, web development, and sales force development (CRM 
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="message-box">
                <h2>We design and develop what works.</h2>
                <p className="lead">
                We strive to give our customers an edge by building high-performance and powerful web and mobile applications. Check out some of the latest projects as you work to create the next big revolution. 
                </p>
              </div>
            </div>
          </div>
          <hr className="hr1" />
          <div className="row">
            <div className="col-md-6">
              <div className="message-box">
                <h2>Skills and experience</h2>
                <p className="lead">
                Qamar Soft Solutions has a team of professionals with years of experience in PHP development, Laravel development, .net development, java, and javascript.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="message-box">
                <h2>Partnership with you</h2>
                <p className="lead">
                We emphasize “innovative” solutions and engagement models to ensure your projects are delivered on time and smoothly within your estimated budget.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Count/>
     <Footer/>

      <a href="#" id="scroll-to-top" className="dmtop global-radius show">
        <i className="fa fa-angle-up" />
      </a>
    </>
  );
};

export default Portfolio;
