import React from 'react';
import './Header.css'
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <>
  {/* Header */}
  <header id="header" className="header">
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="text-container">
            <h1 className="h1-large">
            Getting Started with {" "}
              <span className="replace-me">
              Web Development
              </span>
            </h1>
            <p className="p-large">
            Full service mobile, web, software,AR/VR, and IoT technology solutions. The flexibility of our structure allows us to aim at the quality and specificity of the service offered, creating tailor-made solutions for everyone’s needs.
            </p>
            <Link className="btn12"  to="/contact">ENQUIRY NOW
            </Link>
          </div>
        </div>
        <div className="col-lg-6 img-1">
          <div className="image-container">
            <img
              className="img-fluid"
              src="/static/uploads/img-123.png"
              alt="alternative"
            />
          </div>
        </div>
      </div>
    </div>
  </header>
</>

  )
}

export default Header