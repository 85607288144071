import React from "react";
import Navbar from "../components/Navbar";
import TopNavbar from "../components/TopNavbar";
import Footer from "../components/Footer";

const Contact = () => {
  return (
    <>
      <TopNavbar />
      <Navbar />
      <div className="banner-area banner-bg-1">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="banner">
                <h2>
                  <span>Contact Us</span>
                </h2>
                <ul className="page-title-link">
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li>
                    <a href="/">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="contact" className="section wb">
        <div className="container">
          <div className="section-title text-center">
            <h3>Get in touch</h3>
            <p className="lead">Reach out with any questions you have</p>
          </div>
          {/* end title */}
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="contact_form">
                <div id="message" />
                <form
                  action="https://formspree.io/f/xdovjpzy"
                  method="POST"
                  id="contactform"
                  className="row"
                  name="contactform"
                >
                  <fieldset className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <input
                        type="text"
                        name="firstname"
                        id="first_name"
                        className="form-control"
                        placeholder="First Name"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <input
                        type="text"
                        name="lastname"
                        id="last_name"
                        className="form-control"
                        placeholder="Last Name"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control"
                        placeholder="Your Email"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <input
                        type="number"
                        name="phone"
                        id="phone"
                        className="form-control"
                        placeholder="Your Phone"
                      />
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <textarea
                        className="form-control"
                        name="comments"
                        id="comments"
                        rows={6}
                        placeholder="Give us more details.."
                        defaultValue={""}
                      />
                    </div>
                    <div className="text-center cont-btn">
                      <button
                        type="submit"
                        value="SEND"
                        id="submit"
                        className="btn11"
                      >
                        <span>Submit</span>
                      </button>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
            {/* end col */}
          </div>
          {/* end row */}
          <div className="row">
            <div className="offset-md-1 col-sm-10 col-md-10 offset-sm-1 pd-add">
              <div className="address-item">
                <div className="address-icon">
                  <i className="icon icon-location2" />
                </div>
                <h3>Headquarters</h3>
                <p>450 S Orange Ave Orlando, Florida 32801</p>
              </div>
              <div className="address-item">
                <div className="address-icon">
                  <i className="fa fa-envelope" aria-hidden="true" />
                </div>
                <h3>Email Us</h3>
                <p>
                  info@qamarsoftsolutions.com
                  <br />
                </p>
              </div>
              <div className="address-item">
                <div className="address-icon">
                  <i className="icon icon-headphones" />
                </div>
                <h3>Call Us</h3>
                <p>+1 (321) 710 8967</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <a href="#" id="scroll-to-top" className="dmtop global-radius">
        <i className="fa fa-angle-up" />
      </a>
    </>
  );
};

export default Contact;
