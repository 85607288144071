import React from 'react'

const TopNavbar = () => {
  return (
    <div className="top-bar">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-6">
            <div className="right-top">
              <div className="social-box">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/qamarsoftsolutions" >
                      <i className="fa fa-facebook-square" aria-hidden="true" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/qamarsoftsolutions/">
                      <i className="fa fa-instagram" aria-hidden="true" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/qamar-soft-solutions/?originalSubdomain=in">
                      <i className="fa fa-linkedin-square" aria-hidden="true" />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/qamar_soft?lang=en">
                      <i className="fa fa-twitter-square" aria-hidden="true" />
                    </a>
                  </li>
                  {/* <li>
                    <a href="#">
                      <i className="fa fa-rss-square" aria-hidden="true" />
                    </a>
                  </li> */}
                  <ul></ul>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6">
            <div className="left-top">
              <div className="email-box">
                <a href="mailto:info@qamarsoftsolutions.com">
                  <i className="fa fa-envelope-o" aria-hidden="true" />{" "}
                  info@qamarsoftsolutions.com
                </a>
              </div>
              <div className="phone-box">
                <a href="tel:1234567890">
                  <i className="fa fa-phone" aria-hidden="true" /> +1 (321) 710 8967
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopNavbar
