import React from 'react'
import { NavLink } from 'react-router-dom'

const Navbar = (props) => {
  return (
    <header className="header header_style_01">
    <nav className="megamenu navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <div className="navbar-header">
          <NavLink className="navbar-brand" to="/">
            <img src="/static/images/logos/logo.png" style={{width:"200px"}} alt="image" />
          </NavLink>
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbar"
            aria-controls="navbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>
        </div>
        <div className="collapse navbar-collapse" id="navbar">
          <ul className="navbar-nav ml-auto">
            <li>
              <NavLink className={props.active} to="/" activeClassName="active">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/about" activeClassName="active">About us</NavLink>
            </li>
            <li>
              <NavLink to="/services">Services</NavLink>
            </li>
            <li>
              <NavLink to="/portfolio">Portfolio</NavLink>
            </li>
            <li>
              <NavLink to="/features">Technologies</NavLink>
            </li>
            {/* <li>
              <NavLink to="/testimonials">Testimonials</NavLink>
            </li> */}
            {/* <li>
              <a href="pricing.html">Pricing</a>
            </li> */}
            <li>
              <NavLink to="/contact">Contact</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
  )
}

export default Navbar
