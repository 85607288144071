import React from 'react'
import Navbar from '../components/Navbar'
import TopNavbar from '../components/TopNavbar'

const Testimonial = () => {
  return (
    <>
    <TopNavbar />
      <Navbar />
    <div className="banner-area banner-bg-1">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="banner">
              <h2>
                <span>Testimonials</span>
              </h2>
              <ul className="page-title-link">
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#">Testimonials</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="test-box" className="section wb">
      <div className="container">
        <div className="section-title text-center">
          <h3>Testimonials</h3>
          <p className="lead">
            We thanks for all our awesome testimonials! There are hundreds of our
            happy customers! <br />
            Let's see what others say about Linkweb website template!
          </p>
        </div>
        {/* end title */}
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="testi-carousel owl-carousel owl-theme">
              <div className="testimonial clearfix">
                <div className="desc">
                  <h3>
                    <i className="fa fa-quote-left" /> Wonderful Support!
                  </h3>
                  <p className="lead">
                    They have got my project on time with the competition with a
                    sed highly skilled, and experienced &amp; professional team.
                  </p>
                </div>
                <div className="testi-meta">
                  <h4>
                    James Fernando <small>- Manager of Racer</small>
                  </h4>
                </div>
                {/* end testi-meta */}
              </div>
              {/* end testimonial */}
              <div className="testimonial clearfix">
                <div className="desc">
                  <h3>
                    <i className="fa fa-quote-left" /> Awesome Services!
                  </h3>
                  <p className="lead">
                    Explain to you how all this mistaken idea of denouncing
                    pleasure and praising pain was born and I will give you
                    completed.
                  </p>
                </div>
                <div className="testi-meta">
                  <h4>
                    Jacques Philips <small>- Designer</small>
                  </h4>
                </div>
                {/* end testi-meta */}
              </div>
              {/* end testimonial */}
              <div className="testimonial clearfix">
                <div className="desc">
                  <h3>
                    <i className="fa fa-quote-left" /> Great &amp; Talented Team!
                  </h3>
                  <p className="lead">
                    The master-builder of human happines no one rejects, dislikes
                    avoids pleasure itself, because it is very pursue pleasure.{" "}
                  </p>
                </div>
                <div className="testi-meta">
                  <h4>
                    Venanda Mercy <small>- Newyork City</small>
                  </h4>
                </div>
                {/* end testi-meta */}
              </div>
              {/* end testimonial */}
              <div className="testimonial clearfix">
                <div className="desc">
                  <h3>
                    <i className="fa fa-quote-left" /> Wonderful Support!
                  </h3>
                  <p className="lead">
                    They have got my project on time with the competition with a
                    sed highly skilled, and experienced &amp; professional team.
                  </p>
                </div>
                <div className="testi-meta">
                  <h4>
                    James Fernando <small>- Manager of Racer</small>
                  </h4>
                </div>
                {/* end testi-meta */}
              </div>
              {/* end testimonial */}
              <div className="testimonial clearfix">
                <div className="desc">
                  <h3>
                    <i className="fa fa-quote-left" /> Awesome Services!
                  </h3>
                  <p className="lead">
                    Explain to you how all this mistaken idea of denouncing
                    pleasure and praising pain was born and I will give you
                    completed.
                  </p>
                </div>
                <div className="testi-meta">
                  <h4>
                    Jacques Philips <small>- Designer</small>
                  </h4>
                </div>
                {/* end testi-meta */}
              </div>
              {/* end testimonial */}
              <div className="testimonial clearfix">
                <div className="desc">
                  <h3>
                    <i className="fa fa-quote-left" /> Great &amp; Talented Team!
                  </h3>
                  <p className="lead">
                    The master-builder of human happines no one rejects, dislikes
                    avoids pleasure itself, because it is very pursue pleasure.{" "}
                  </p>
                </div>
                <div className="testi-meta">
                  <h4>
                    Venanda Mercy <small>- Newyork City</small>
                  </h4>
                </div>
                {/* end testi-meta */}
              </div>
              {/* end testimonial */}
            </div>
            {/* end carousel */}
          </div>
          {/* end col */}
        </div>
        {/* end row */}
      </div>
      {/* end container */}
    </div>
    {/* end section */}
    <div
      id="testimonials"
      className="parallax section db parallax-off"
      style={{ backgroundImage: 'url("uploads/parallax_03.jpg")' }}
    >
      <div className="container">
        <div className="section-title text-center">
          <h3>Our Clients</h3>
          <p className="lead">
            We thanks for all our awesome testimonials! There are hundreds of our
            happy customers! <br />
            Let's see what others say about GoodWEB Solutions website template!
          </p>
        </div>
        {/* end title */}
        <hr className="hr1" />
        <div className="row logos">
          <div className="col-md-2 col-sm-2 wow fadeInUp">
            <a href="#">
              <img src="/static/uploads/logo_01.png" alt="" className="img-repsonsive" />
            </a>
          </div>
          <div className="col-md-2 col-sm-2 wow fadeInUp">
            <a href="#">
              <img src="/static/uploads/logo_02.png" alt="" className="img-repsonsive" />
            </a>
          </div>
          <div className="col-md-2 col-sm-2 wow fadeInUp">
            <a href="#">
              <img src="/static/uploads/logo_03.png" alt="" className="img-repsonsive" />
            </a>
          </div>
          <div className="col-md-2 col-sm-2 wow fadeInUp">
            <a href="#">
              <img src="/static/uploads/logo_04.png" alt="" className="img-repsonsive" />
            </a>
          </div>
          <div className="col-md-2 col-sm-2 wow fadeInUp">
            <a href="#">
              <img src="/static/uploads/logo_05.png" alt="" className="img-repsonsive" />
            </a>
          </div>
          <div className="col-md-2 col-sm-2 wow fadeInUp">
            <a href="#">
              <img src="/static/uploads/logo_06.png" alt="" className="img-repsonsive" />
            </a>
          </div>
        </div>
        {/* end row */}
      </div>
      {/* end container */}
    </div>
    {/* end section */}
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-3">
            <div className="widget clearfix">
              <div className="widget-title">
                <img src="/static/images/logos/logo-2.png" alt="" />
              </div>
              <p>
                {" "}
                Integer rutrum ligula eu dignissim laoreet. Pellentesque venenatis
                nibh sed tellus faucibus bibendum. Sed fermentum est vitae rhoncus
                molestie. Cum sociis natoque penatibus et magnis dis montes.
              </p>
            </div>
            {/* end clearfix */}
          </div>
          {/* end col */}
          <div className="col-md-6 col-lg-3">
            <div className="widget clearfix">
              <div className="widget-title">
                <h3>Useful links</h3>
              </div>
              <ul className="footer-links hov">
                <li>
                  <a href="#">
                    Home <span className="icon icon-arrow-right2" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    Blog <span className="icon icon-arrow-right2" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    Pricing <span className="icon icon-arrow-right2" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    About <span className="icon icon-arrow-right2" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    Faq <span className="icon icon-arrow-right2" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    Contact <span className="icon icon-arrow-right2" />
                  </a>
                </li>
              </ul>
              {/* end links */}
            </div>
            {/* end clearfix */}
          </div>
          {/* end col */}
          <div className="col-md-6 col-lg-3">
            <div className="footer-recent clearfix">
              <div className="widget-title">
                <h3>Recent posts</h3>
              </div>
              <ul>
                <li>
                  <i className="fa fa-user" aria-hidden="true" />
                  <span>Posted By : Ahmed Rubel</span>
                  <i className="fa fa-clock-o" aria-hidden="true" />
                  <span> Time : 1.30 Am</span>
                  <h2>Etiam dignissim augue a commodo</h2>
                </li>
                <li>
                  <i className="fa fa-user" aria-hidden="true" />
                  <span>Posted By : Ahmed Rubel</span>
                  <i className="fa fa-clock-o" aria-hidden="true" />
                  <span> Time : 1.30 Am</span>
                  <h2>Etiam dignissim augue a commodo</h2>
                </li>
                <li>
                  <i className="fa fa-user" aria-hidden="true" />
                  <span>Posted By : Ahmed Rubel</span>
                  <i className="fa fa-clock-o" aria-hidden="true" />
                  <span> Time : 1.30 Am</span>
                  <h2>Etiam dignissim augue a commodo</h2>
                </li>
              </ul>
            </div>
            {/* end clearfix */}
          </div>
          {/* end col */}
          <div className="col-md-6 col-lg-3">
            <div className="widget footer-contact clearfix">
              <div className="widget-title">
                <h3>Contact Us</h3>
              </div>
              <ul>
                <li>
                  Address: PO Box 16122 Collins Street West Victoria 8007
                  Australia
                </li>
                <li>
                  Phone: <a href="#">+62 3 8376 8080</a>
                </li>
                <li>
                  Email: <a href="#">info@yoursite.com </a>
                </li>
              </ul>
              <ul className="social-list">
                <li>
                  <a href="https://www.facebook.com/" className="facebook">
                    <i className="fa fa-facebook" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/" className="twitter">
                    <i className="fa fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="https://linkedin.com/" className="linkedin">
                    <i className="fa fa-linkedin" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/" className="instagram">
                    <i className="fa fa-instagram" />
                  </a>
                </li>
                <li>
                  <a href="https://plus.google.com/" className="googlePlus">
                    <i className="fa fa-google-plus" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* end row */}
      </div>
      {/* end container */}
    </footer>
    {/* end footer */}
    <div className="copyrights">
      <div className="container">
        <div className="footer-distributed">
          <div className="footer-left">
            <p className="footer-company-name">
              All Rights Reserved. © 2018 <a href="#">Linkweb</a> Design By :
              <a href="https://html.design/">html design</a>
            </p>
          </div>
        </div>
      </div>
      {/* end container */}
    </div>
    {/* end copyrights */}
    <a href="#" id="scroll-to-top" className="dmtop global-radius">
      <i className="fa fa-angle-up" />
    </a>
  </>
  
  )
}

export default Testimonial

