import React from 'react'
import Navbar from '../components/Navbar'
import TopNavbar from '../components/TopNavbar'
import Footer from '../components/Footer'
import LearnMore from '../components/LearnMore'

const About = (props) => {
  return (
    <>
 <TopNavbar className={props.active}/>
 <Navbar />
  <div className="banner-area banner-bg-1">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="banner">
            <h2>
              <span>About Us</span>
            </h2>
            <ul className="page-title-link">
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <a href="#">About Us</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <LearnMore/>
  <Footer/>
  <a href="#" id="scroll-to-top" className="dmtop global-radius">
    <i className="fa fa-angle-up" />
  </a>
</>

  )
}

export default About
