import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <>
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-3">
            <div className="widget clearfix">
              <div className="widget-title">
                <img src="/static/images/logos/logo.png" style={{width:"14rem"}} alt="" />
              </div>
              <p>
              We are skilled technologists that apply our passion for technology to create successful products and solutions for our clients. Our passion drives us to be innovative, creative, and experts.
              </p>
            </div>
            {/* end clearfix */}
          </div>
          {/* end col */}
          <div className="col-md-6 col-lg-3">
            <div className="widget clearfix">
              <div className="widget-title">
                <h3>QUICK LINKS</h3>
              </div>
              <ul className="footer-links hov">
                <li>
                  <Link to="/">
                    Home <span className="icon icon-arrow-right2" />
                  </Link>
                </li>
                <li>
                  <Link to="/about">
                    About <span className="icon icon-arrow-right2" />
                  </Link>
                </li>
                <li>
                  <Link to="/services">
                  Service <span className="icon icon-arrow-right2" />
                  </Link>
                </li>
                <li>
                  <Link to="/portfolio">
                  Portfolio <span className="icon icon-arrow-right2" />
                  </Link>
                </li>
                <li>
                  <Link to="/features">
                  Technologies <span className="icon icon-arrow-right2" />
                  </Link>
                </li>
                <li>
                  <a href="/contact">
                    Contact <span className="icon icon-arrow-right2" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="widget clearfix">
              <div className="widget-title">
                <h3>SERVICES</h3>
              </div>
              <ul className="footer-links hov">
                <li>
                  <Link to="/services">
                  Animation Services <span className="icon icon-arrow-right2" />
                  </Link>
                </li>
                <li>
                  <Link to="/services">
                  Mobile App Development <span className="icon icon-arrow-right2" />
                  </Link>
                </li>
                <li>
                  <Link to="/services">
                  Backend Development <span className="icon icon-arrow-right2" />
                  </Link>
                </li>
                <li>
                  <Link to="/services">
                  Software Consulting <span className="icon icon-arrow-right2" />
                  </Link>
                </li>
                <li>
                  <Link to="/services">
                  Web Development <span className="icon icon-arrow-right2" />
                  </Link>
                </li>
                <li>
                  <Link to="/services">
                  Salesforce Development (CRM) <span className="icon icon-arrow-right2" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="widget footer-contact clearfix">
              <div className="widget-title">
                <h3>Contact Us</h3>
              </div>
              <ul>
                <li>
                  Address: PO Box 16122 Collins Street West Victoria 8007
                  Australia
                </li>
                <li>
                  Phone: <a href="tel:1234567890">+1 (321) 710 8967</a>
                </li>
                <li>
                  Email: <a href="mailto:info@qamarsoftsolutions.com">info@qamarsoftsolutions.com </a>
                </li>
              </ul>
              <ul className="social-list">
                <li>
                  <a href="https://www.facebook.com/qamarsoftsolutions" className="facebook">
                    <i className="fa fa-facebook" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/qamar_soft?lang=en" className="twitter">
                    <i className="fa fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/qamar-soft-solutions/?originalSubdomain=in/" className="linkedin">
                    <i className="fa fa-linkedin" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/qamarsoftsolutions/" className="instagram">
                    <i className="fa fa-instagram" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>

    <div className="copyrights">
        <div className="container">
          <div className="footer-distributed">
            <div className="footer-left">
              <p className="footer-company-name">
                All Rights Reserved. © 2023 <a href="/">Qamar Soft Solutions</a> Design By :
                <a href="/">webjob.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
    
  )
}

export default Footer